<template>
  <b-row>
    <b-col cols="12" v-if="can('read/manajemen-pegawai')">
      <b-card title="Manajemen Pegawai">
        <b-row class="pb-2">
          <b-col> </b-col>
          <b-col md="4" xs="12">
            <b-input-group size="8">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Cari disini..."
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Hapus
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-table
          striped
          hover
          responsive
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          :busy="busy"
          @filtered="onFiltered"
        >

          <template #table-busy>
            <div class="text-center my-3">
              <!-- <b-spinner small type="grow" label="Loading..."></b-spinner> -->
              <div>Loading data, please wait...</div>
            </div>
          </template>

          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>

          <template #emptyfiltered>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>

          <template #cell(no)="data">
            {{ (currentPage - 1) * perPage + (data.index + 1) }}
          </template>

          <template #cell(nopend)="data">
            {{ data.item.namaktr }} - {{ data.item.nopend }}
          </template>

          <template #cell(statuskerja)="data">
            <span v-if="data.item.statuskerja == 1"> Aktif </span>
            <span v-else-if="data.item.statuskerja == 2"> CBS Akhir </span>
            <span v-else-if="data.item.statuskerja == 10">
              Proses Sertijab
            </span>
            <span v-else-if="data.item.statuskerja == 'Z'">
              Tidak Menjalani MPP
            </span>
            <span v-else>
              {{ data.item.statuskerja }}
            </span>
          </template>

          <!-- A virtual column -->
          <template #cell(Aksi)="data">
            <div style="min-width: 80px">
              <b-button
                v-if="can('show/manajemen-pegawai')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                v-b-popover.hover.bottom="'Detail'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="gotoDetailPage(data.item.nippos)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
        <span v-if="totalRows == 1" style="margin: 1rem"></span>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per Page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            />
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col v-else>
      <b-card>
        <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">Tidak Memiliki Akses</h2>
            <p class="mb-2">Anda tidak memiliki akses pada halaman ini</p>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCol,
  BModal,
  VBModal,
  BForm,
  BFormRadioGroup,
  BFormTextarea,
  VBPopover,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@axios'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    vSelect,
    BModal,
    BCardCode,
    BForm,
    BFormRadioGroup,
    BFormTextarea,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-popover': VBPopover,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      config: {
        api: '/employees',
        api_employee: '/misc/employees/v3',
      },
      perPage: 10,
      pageOptions: [1, 10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'nama',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        'no',
        { key: 'nama', label: 'Nama', thStyle: 'min-width:200px' },
        { key: 'nippos', label: 'NIPPOS' },
        { key: 'namabagian', label: 'Bagian', thStyle: 'min-width:250px' },
        { key: 'descjabatan', label: 'Jabatan', thStyle: 'min-width:160px' },
        { key: 'nopend', label: 'Kantor', thStyle: 'min-width:260px' },
        { key: 'statuskerja', label: 'Status', thStyle: 'min-width:150px' },
        { key: 'Aksi', label: 'Aksi' },
      ],
      items: [],
      busy: false,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    gotoDetailPage(id) {
      let _ = this
      _.$router.push('/manajemen-pegawai/' + id + '/detail')
    },
    get() {
      let _ = this
      let uri = _.config.api
      axios
        .get(uri + '?company=' + _.userData.nopend)
        .then(resp => {
          _.items = resp.data.data
          _.totalRows = _.items.length
        })
        .catch(e => {
          console.log(e)
        })
    },
    getEmployee() {
      let _ = this
      _.busy = true;
      let uri = _.config.api_employee
      axios
        .get(uri + '?region=' + _.userData.regional)
        .then(resp => {
          _.items = resp.data.data
          _.totalRows = _.items.length
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          _.busy = false;
        });
    },
  },
  created() {
    let _ = this
    if (_.userData.role_name == 'Admin Nasional') {
      _.getEmployee()
    } else {
      _.get()
    }
  },
}
</script>
